import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Container, Row, Col, Button, Form, Alert, Badge, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { UserState, folderState, blogState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import { URL as URL2 } from '../../config';
import { getFolders, getBlogs } from '../apiHelpers';
import axios from 'axios';
import SearchModal from '../DiscoverView/SearchModal';
import { ImagePlus } from 'lucide-react';
import LoadingGif from '../../images/loading.gif';
import { useDropzone } from 'react-dropzone';
import heic2any from 'heic2any';


const NewBlog = () => {
    const [tripName, setTripName] = useState('');
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const [user, setUser] = useRecoilState(UserState);
    const [folders, setFolders] = useRecoilState(folderState);
    const [success, setSuccess] = useState(false);
    const [places, setPlaces] = useState([]);
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [filePath, setFilePath] = useState(null);
    const [blogs, setBlogs] = useRecoilState(blogState);

    const onDrop = useCallback((acceptedFiles) => {
        setFile(acceptedFiles[0]);
    }, []);

    useEffect(() => {
        if (!!file) {
            if (file.type === 'image/heic' || file.type === 'image/heif') {
                // Convert HEIC to JPEG using heic2any
                heic2any({ blob: file, toType: 'image/jpeg' })
                .then((convertedBlob) => {
                    // Create an object URL for the converted file and set it as the file path
                    const convertedUrl = URL.createObjectURL(convertedBlob);
                    setFilePath(convertedUrl);
                })
                .catch((error) => {
                    console.error('Error converting HEIC to JPEG:', error);
                });
            } else {
                setFilePath(URL.createObjectURL(file));
            }
        }
    }, [file]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const createMemory = async (e) => {
        setLoading(true);
        e.preventDefault();
        ReactGA.event({
            category: "Blogs",
            action: "User Creates New Blog"
        })
        if (tripName.length < 1 || file === null || places.length === 0) {
            setError(true);
            setLoading(false);
            return;
        } else {
            setError(false);
        }

        const formData = new FormData();
        const imageFile = file;
        formData.append('image', imageFile);
        formData.append('name', tripName);
        console.log('places: ', places);
        const formattedPlaces = places.reduce((acc, place) => {
            const newPlace = {};
            console.log('place: ', place);
            newPlace.formatted_address = place.formatted_address;
            newPlace.geometry = {}
            newPlace.geometry.location = {}
            newPlace.geometry.location.lat = place.geometry.location.lat;
            newPlace.geometry.location.lng = place.geometry.location.lng;
            console.log('geo type: ', typeof place.geometry.location.lat);
            acc.push(newPlace);
            return acc;
        }, []);
        console.log('place with details: ', formattedPlaces);
        formData.append('locations', JSON.stringify(formattedPlaces));
        // const res = await axios.post(`${URL2}/trips/create/blog/name/${tripName}/user/${user.id}/`, formData);
        try {
            const res = await axios.post(`${URL2}/blogs/user/${user.id}/create`, formData);
            if (res.status === 201) {
                const id = res.data;
                setLoading(false);
                setSuccess(true);
                // await getFolders(user, setFolders);
                await getBlogs(user, setBlogs);
                setTimeout(() => navigate(`/guide/${id}`), 2100);
                setTimeout(() => setSuccess(false), 2000);
            }
            if (res.status === 500) {
                setLoading(false);
                setError(true);
            }
        } catch (err) {
            setLoading(false);
            setError(true);
        }
    }

    const handleUpload = () => {
        inputRef.current?.click();
    }

    const setImage = (e) => {
        setFile(URL.createObjectURL(inputRef.current.files[0]))
    }

    const styles = {
        dropzone: {
            width: '95%',
            height: '95%',
            border: '2px dashed white',
            borderRadius: '10px',
            textAlign: 'center',
            padding: '10px',
            margin: 'auto',
            cursor: 'pointer', // Ensures it looks clickable
            zIndex: "2"
          },
      };

    
    return (
        <div className='main'>
        {!!user && !user.content_creator ? (
            <Container>
                <Row>
                    <Col>
                        <h4 className='mb-3 mt-3' style={{ textDecoration: "underline" }}>You do not currently have sharing permissions.</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h6 className='text-secondary mb-3'>The ability to share on Trottr is invite only. We do this to ensure the quality of content on our site.</h6>
                        <h6 className='text-secondary mb-3'>We encourage you to use the other features on our site including the "Dream" tab where you can bookmark posts that have inspired you or the "Plan" tab where you can create detailed itineraries in collaboration with your friends.</h6>
                        <h6 className='text-secondary mb-3'>If you have not been invited but would like to share you can submit a request via email to support@trottr.com.</h6>
                        <h6 className='text-secondary mb-5'>Please include any relevant social media handles to help us evaluate your account.</h6>
                    </Col>
                </Row>
            </Container>
        ) : (
            <Container>
                <Row>
                    <Col className='col-md-6 offset-md-3 mt-3'>
                        <h4>Create a Trip Guide to start sharing places you recommend along with relevant photos and videos!</h4>
                        {!!loading && (
                            <div className='full-center w-75' style={{ zIndex: "3" }}>
                                <Image src={LoadingGif} className='w-100' />
                            </div>
                        )}
                        <Form>
                            <Row className='text-left mt-3 border-bottom border-black-50 shadow'>
                                <Col>
                                    <Row>
                                        <Col className='trip-cover-img-bg position-relative' style={{ backgroundImage: filePath ? `url(${filePath})` : "none" }}>
                                            {!file && (
                                                <div className='full-center' {...getRootProps()} style={styles.dropzone}>
                                                    <input {...getInputProps()} 
                                                        accept='video/*,image/*'
                                                    />
                                                    {isDragActive ? (
                                                        <p className='text-white full-center'>Drop the files here...</p>
                                                    ) : (
                                                        <div className='full-center w-100 pr-3 pl-3'>
                                                            <ImagePlus size={100} color='white' />
                                                            <p className='text-white'>1) Drag 'n' drop a cover photo here, or click to select file</p>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Col>
                                        {/* <Image className='mw-100' src={file} alt='trip background' /> */}
                                    </Row>
                                    <Row>
                                        <Col className='mt-2 mb-2'>
                                            <Form.Control style={{ fontSize: "1.2rem" }} placeholder='2) Name a Past Trip!' value={tripName} onChange={(e) => setTripName(e.target.value)} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='mt-5'>
                                <Col>
                                <p style={{ fontSize: "1.2rem"}} className='mb-0'>3) Tag locations so others can discover your Trip Guide!</p>
                                    <SearchModal setPlaces={setPlaces} postView={true} blogView={false} places={places} iconSize={64} />
                                    <div className='mt-3'>
                                    {places.length > 0 && places.map((place, i) => (
                                        <h4 className='d-inline mr-2' key={i}>
                                            <Badge className='p-2' variant='light'>{place.formatted_address}</Badge>
                                        </h4>
                                    ))}
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-3 text-center'>
                                <Col>
                                    <Button className='rounded-pill' onClick={createMemory}>Create</Button>
                                </Col>
                            </Row>
                            {!!success && (
                                <Row className='mt-3 text-center'>
                                    <Col>
                                        <Alert variant='success'>Album Created!</Alert>
                                    </Col>
                                </Row>
                            )}
                            {!!error && (
                                <Row className='mt-3 text-center'>
                                    <Col>
                                        <Alert variant='danger'>Error: Please ensure you have a cover photo, album name that does not exceed 100 characters, and at least one location.</Alert>
                                    </Col>
                                </Row>
                            )}
                        </Form>
                    </Col>
                </Row>
            </Container>
        )}
        </div>
    );
};

export default NewBlog;