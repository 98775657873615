import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Form, Button } from 'react-bootstrap';
import moment from 'moment';
import { folderState, UserState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import 'react-dates/initialize';

import { DateTimePicker } from 'react-rainbow-components';
import ReactGA from 'react-ga4';






const SelectTime = ({ item, changeItemDate, index, folderid, folder }) => {
    const [itemTime, setItemTime] = useState(item.scheduled_time);
    const itemId = item._id;
    const [user, setUser] = useRecoilState(UserState);
    const [folders, setFolders] = useRecoilState(folderState);
    const [showUpdate, setShowUpdate] = useState(false);
    const [focused, setFocused] = useState(false);
    // const [date, setDate] = useState(item.scheduled_time !== null ? new Date(item.scheduled_time): new Date());
    const [date, setDate] = useState(null);
    const [value, onChange] = useState(new Date());
    const today = new Date();
    const [startDate, setStartDate] = useState(folder.start_date != null ? new Date(folder.start_date) : new Date(today.setFullYear(today.getFullYear() - 100)));
    const [endDate, setEndDate] = useState(folder.end_date != null ? new Date(folder.end_date) : new Date(today.setFullYear(today.getFullYear() + 200)));


    const dateForPicker = (itemTime) => {
        console.log(itemTime);
        return moment(new Date(itemTime));
    };

    const dateToString = (date) => {
        return moment(new Date(date)).format('LLLL').toString();
    };

    const handleDateChange = (date) => {
        setDate(date);
        console.log('date: ', date);
        if (date !== null) {
            changeItemDate(index, date, folderid, user.id);
            ReactGA.event({
                category: "Trips",
                action: "User Added Time to Itinerary Item"
            })
        }
    };

    const handleDateSubmit = (e) => {
        setShowUpdate(false);
        changeItemDate(index, itemTime, folderid, user.id)
        // .then(() => {
        //     console.log('folders after submit: ', folders);
        //     console.log('new item date: ', )
        // })
    };

    const handleFocusChange = ({focused}) => {
        console.log(focused)
        setFocused(focused)
    }


    useEffect(() => {
        const momentFormat = "YYYY-MM-DD HH:mm:ss";
        if (moment(new Date(item.scheduled_time)).format(momentFormat) != moment(new Date(date)).format(momentFormat)) {
            if (item.scheduled_time === null) {
                // console.log('setting default date');
                setDate(null)
            } else {
                setDate(moment.utc(new Date(item.scheduled_time)));
            }
        }

        if (startDate !== folder.start_date && folder.start_date != null) {
            setStartDate(new Date(folder.start_date));
        }

        if (endDate !== folder.end_date && folder.end_date != null) {
            setEndDate(new Date(folder.end_date));
        }

    }, [folders]);


    const theme = {
        rainbow: {
            palette: {
                brand: '#15084D',
            },
        },
    };

    return(
        <Form>
            <Row className='pl-3 pr-3 pb-2 pt-2 m-0 text-left'>
                {/* <Col className='left-align my-auto m-0 p-1'> */}
                    <Form.Label className='mt-auto mb-auto mr-1 d-inline'><strong>WHEN:</strong></Form.Label>
                {/* </Col> */}
                {/* <Col className='left-align pb-1 pt-1 pr-0 pl-0'> */}
                    <div className='d-inline rainbow-align-content_left rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto'>
                        <DateTimePicker
                            className='rainbow-input'
                            value={date}
                            onChange={handleDateChange}
                            onBlur={handleDateChange}
                            formatStyle="medium"
                            placeholder='Date & Time'
                            style={{ width: "15em" }}
                            minDate={startDate}
                            maxDate={endDate}
                        />
                    </div>
                {/* </Col> */}
            </Row>
        </Form>
    )
};

export default SelectTime;