import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Image } from 'react-bootstrap';
import axios from 'axios';
import { URL } from '../../config';
import { getUserInfo } from '../apiHelpers';
import ReactGA from 'react-ga4';
import { Pencil } from 'lucide-react';


const BioDetails = ({ user, setUser, followView }) => {
    const [bio, setBio] = useState(!!user.bio ? user.bio : '');
    const [bioEditable, setBioEditable] = useState(false);
    const [showFullAbout, setShowFullAbout] = useState(false);

    const toggleAbout = () => {
        setShowFullAbout(showFullAbout => !showFullAbout);
    }

    const getAboutLength = () => {
        if (!!user.bio) {
            return user.bio.length
        }
    }

    const handleBioSubmit = async (e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Profile',
            action: 'User Edits Bio'
        })
        const url = `${URL}/users/${user.id}/profile/bio`;
        const res = await axios.post(url, { bio });
        if (res.status === 200) {
            await getUserInfo(user.email, setUser);
        }
        setBioEditable(false);

    }

    useEffect(() => {
        if (!!user.bio) {
            setBio(user.bio);
        }
    }, [user])

    

    return (
        <>
            {!!bioEditable ? (
                <Row className='m-0 p-0 mw-100 w-100'>
                    <Col className='mt-0'>
                        <Form onSubmit={handleBioSubmit} className='ml-0 mr-1'>
                            <Form.Group as={Row} className='mt-0 mb-1 pb-0 text-left'>
                                <Form.Label className='mb-0'><h6 className='mb-0'>About Me:</h6></Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} className='mb-1'>
                                <Form.Control as="textarea" rows={2} placeholder='Tell us about yourself.' value={bio} onChange={e => setBio(e.target.value)} id='bioInput' />
                            </Form.Group>
                            <Form.Group as={Row} className='mb-1 pr-0'>
                                <Col className='right-align d-inline'>
                                    {/* <Button className='btn-primary p-1 d-inline' type="submit" size="sm"><Image className='m-0 filter-white' style={{ height: "1.8em" }} src={SaveIcon} /></Button> */}
                                    <Button className='btn-secondary pr-2 pl-2 pt-1 pb-1 d-inline ml-2 mb-1' onClick={() => setBioEditable(false)} size="md">Cancel</Button>
                                    <Button className='btn-primary pr-2 pl-2 pt-1 pb-1 d-inline ml-2 mb-1' type="submit" size="md">Save</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                )
                : (
                <Row className='m-0 p-0 mw-100 w-100'>
                    <Col className='mt-0'>
                        <Row className='mt-0 mb-0'>
                            <Col className='mt-1 pl-0 col-md-9 left-align'>
                                <h6 className='m-0'>About Me:</h6>
                            </Col>
                            {!followView && (
                                <Col className='mt-0 col-md-3 right-align'>
                                    <Button size="sm" className='btn-transparent' onClick={() => setBioEditable(true)}><Pencil color='#424242' size={20} /></Button>
                                </Col>
                            )}
                        </Row>
                        <Row className='text-left'>
                            <Col className='pl-0 mw-100'>
                                {getAboutLength() > 200 ? !showFullAbout ? 
                                    <p className="mb-2 text-dark">{user.bio.slice(0,200)}...<span className='cursor-pointer text-dark show-details' onClick={toggleAbout}>(more)</span></p>
                                    : <p className="mb-2 text-dark">{user.bio}<span className='cursor-pointer text-dark show-details' onClick={toggleAbout}> (less)</span></p>
                                    : <p className="mb-2 text-dark">{!!user.bio ? user.bio : (followView && !user.bio) ? 'This user has not written a bio yet.' : 'Tell us about yourself.'}</p>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                )
            }
        </>
    )
};

export default BioDetails;